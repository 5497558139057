import styled from 'styled-components';

export const Container = styled.div`
  display: flex;
  justify-content: center;
  align-items: center;
  background: var(--background);
  height: calc(100vh - 20px);
  overflow: hidden;
`;
